<template>
	<div class="mainBox">
		<div class="logo"><img src="../assets/logo.png" style="width: 20%;" /></div>
		<div class="formBoxs" v-if="code == 200">
			<div class="logo"><img src="../assets/title2.png" style="width: 50%;" /></div>
			<div style="font-size: 12px;margin-bottom: 20px;">證書編號：<van-rolling-text :start-num="0"
					:target-num="allData.certNo.ijci_no" stop-order="ltr" height="11" :duration="0.8" />
			</div>
			<div><img src="../assets/text1.png" style="width: 80%;" /></div>
			<div class="infoBox">
				<div class="ibox">
					<div class="item"><span style="font-weight: bold;">承諾人：</span>
						<div class="user">{{allData.man.ijc_name}}先生</div>
					</div>
					<div class="item2"><img src="../assets/heart.png" style="width: 60%;" /></div>
					<div class="item"><span style="font-weight: bold;">受諾人：</span>
						<div class="user">{{allData.woman.ijc_name}}女士</div>
					</div>
				</div>
				<div style="margin-top: 50px;text-align: center;color: #868686;font-size: 11px;margin-bottom: 20px;">
					你們的信息已在ISTERO真愛系統中終身綁定</div>
			</div>
		</div>
		<div class="formBoxs2" v-else>
			<div style="padding: 80px 50px;">
				<van-loading size="24px" type="spinner" v-if="!loading" vertical>真愛證書加載中</van-loading>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast
	} from 'vant';
	import {
		showNotify
	} from 'vant';

	import {
		getInfo
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				card: "",
				allData: {},
				sex: "",
				password: "",
				code: "",
				message: "",
				show: false,
				username: "",
				idcard: "",
				banner: "",
				loading: false,
				loading2: false,
			}
		},
		created() {
			let that = this
			that.getInfo()

		},
		setup() {
			const router = useRouter()
			const route = useRoute()
		},
		methods: {
			getInfo() {
				let that = this
				getInfo({}).then(res => {
					if (res.code == 200) {
						that.allData = res.data
						that.code = res.code
					} else {
						that.$router.push({
							path: "/"
						})
					}
				})
			}
		}
	}
</script>
<style>
	body {
		background-image: url("../assets/bg.jpg");
		background-size: 100% 100%;
		min-height: 100vh;
		/* 视口高度 */
		/* background-size: cover; */
		/* 背景图片覆盖整个元素 */
		background-position: center;
		/* 背景图片居中 */
		background-repeat: no-repeat;
		/* 背景图片不重复 */
		width: 100%;
		background-attachment: fixed;
		overflow: hidden;
	}

	.mainBox {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-top: 50px;
	}



	.mainBox .formBoxs {
		width: 85%;
		padding: 70px 15px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;
		background-image: url('../assets/cert_bg.png');
		background-size: 100% 100%;

	}

	.mainBox .formBoxs2 {
		width: 85%;
		padding: 70px 15px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;

	}

	.mainBox .infoBox {
		text-align: left;
		padding: 10px;
		font-size: 15px;
	}

	.mainBox .infoBox .ibox {
		display: flex;
		justify-content: center;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		flex-wrap: wrap;
	}

	.mainBox .infoBox .ibox>.item {
		width: 40%;
		text-align: center;
	}

	.mainBox .infoBox .ibox>.item .user {
		border-bottom: 1px dashed #000;
		margin-top: 10px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
		padding: 5px;
	}

	.mainBox .infoBox .ibox>.item2 {
		width: 20%;
		text-align: center;
	}



	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.mainBox .btnBox {
		width: 95%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
</style>